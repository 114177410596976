import { StaticImage } from "gatsby-plugin-image"
import * as React from "react"

const PlayButton = ({onPlay, onPause}) => {

    const [isPlaying, setIsPlaying] = React.useState(false)
     
    const pause = () => {
        setIsPlaying(false)
        onPause(true)
    } 
    const play = () => {
        setIsPlaying(true)
        onPlay(true)
    }

    return (<div>

        {isPlaying ? 
        <div onClick={pause}>
            <StaticImage alt="image pause" src={'../../../images/pause.png'} width={50} height={50}></StaticImage>
        </div> :         
        <div onClick={play}>
            <StaticImage alt="image play" src={'../../../images/play.png'} width={50} height={50}></StaticImage>
        </div>}

    </div>)
}

export default PlayButton