import * as React from "react"
import StarIcon from "@material-ui/icons/Star"
import CloudDownloadIcon from "@material-ui/icons/CloudDownload"
import ShareIcon from "@material-ui/icons/Share"
import CastConnectedIcon from "@material-ui/icons/CastConnected"

const SharingBar = () => {
  return (
    <div style={{ display: "none" }} className="flex w-full px-6 shadow py-4">
      <div
        className="w-4/6 flex justify-start items-center text-sc-black font-bold "
        style={{ fontSize: 16 }}
      >
        <span>
          4.5/5 <StarIcon color="#0C0C0C"></StarIcon> {}
        </span>
      </div>
      <div className="w-2/6 flex justify-around items-center">
        <div className="text-sc-black" style={{ fontSize: 16 }}>
          <span style={{ display: "none" }}>
            <CloudDownloadIcon color="#0C0C0C"></CloudDownloadIcon>
          </span>
        </div>
        <div className="text-sc-black" style={{ fontSize: 16 }}>
          <span style={{ display: "none" }}>
            <ShareIcon color="#0C0C0C"></ShareIcon>
          </span>
        </div>
        <div className="text-sc-black" style={{ fontSize: 16 }}>
          <span>
            <CastConnectedIcon color="#0C0C0C"></CastConnectedIcon>
          </span>
        </div>
      </div>
    </div>
  )
}

export default SharingBar
