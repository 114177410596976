import * as React from "react"
import  "../../../styles/site.css"

const ProgressBar = ({  audio}) => {

  const rangeRef = React.useRef(null);
  const [percentage, setPercentage] = React.useState(0)
  
  
  const convertTime = seconds =>{
    let minute = Math.floor((seconds/60)%60)
    minute = (minute < 10) ? '0' + minute : minute
    let second = Math.floor(seconds % 60)
    second = (second <10)? '0'+second: second
    
    return minute +':'+second
  }

  const handleChange = (e) => {
    let time = 0
    time = e.target.value 
    time = time *audio.duration /100
    audio.currentTime = time
  }

  React.useEffect (()=>{
    if(audio){
      setPercentage(Math.floor(audio.currentTime * 100 /audio.duration))
    }else{
      setPercentage(0)
    }
    //  console.log(percentage)
   
  })
  return (

    <>
      <div className="w-full mb-0 mt-0">
          <div className="basis-1/4 w-64 hover:translate-x-1">
            {audio? convertTime(audio.currentTime) : '0:00'} / 
            {audio? convertTime(audio.duration - audio.currentTime): '0:00'}
          </div>
      </div>
      <div className="w-full mb-6 mt-2 md:mt-0 md:mb-8 lg:mt-0 lg:mb-16 relative bg-sc-body rounded-full h-1">
        <div className="bg-sc-primary h-2 rounded-full peer-hover:h-3.5 absolute z-10" style={{width: `${percentage}%`}}>
        </div>
        <input 
          className=" slider w-full appearance-none mr-2 rounded-full absolute h-2 bg-sc-body"
          type="range" 
          ref={rangeRef}
          min="0" 
          max="100" 
          step=".1" 
          value={percentage}
          onChange={handleChange}>
        </input>
      </div>
    </>
  )
}

export default ProgressBar
