import * as React from "react"
import { MdReplay10 } from "@react-icons/all-files/md/MdReplay10"
import { MdForward10 } from "@react-icons/all-files/md/MdForward10"
import PlayButton from "./PlayButton"

const ControlBar = ({ onPlay, onPause, audio }) => {
  const increaseTime = () => {
    audio.currentTime = audio.currentTime + 10
  }

  const decreaseTime = () => {
    audio.currentTime = audio.currentTime - 10
  }

  return (
    <div className="flex py-12">
      <div>
        <MdReplay10
          color="black"
          size={50}
          onClick={decreaseTime}
        ></MdReplay10>
      </div>
      <div>
        <PlayButton onPause={onPause} onPlay={onPlay}></PlayButton>
      </div>
      <div>
        <MdForward10
          color="black"
          size={50}
          onClick={increaseTime}
        ></MdForward10>
      </div>
    </div>
  )
}

export default ControlBar
