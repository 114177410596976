import * as React from "react"
import ProgressBar from "./ProgressBar"
import SharingBar from "./SharingBar"
import { BiHeart } from "@react-icons/all-files/bi/BiHeart"
import ControlBar from "./ControlBar"
import useAudioPlayer from "./usePlayer"

const AudioPlayer = ({ portrait, source, onClick, onEnded }) => {
  const [progress, setProgress] = React.useState()
  const { audio, curTime, duration, playing, setPlaying, setClickedTime } =
    useAudioPlayer()

  React.useEffect(() => {
    const handleEnded = () => {
      onEnded() // Llamamos a la función onEnded cuando el audio termina
    }

    if (audio) {
      audio.addEventListener("ended", handleEnded) // Escuchamos el evento 'ended' del audio
    }

    return () => {
      if (audio) {
        audio.removeEventListener("ended", handleEnded) // Limpiamos el listener cuando el componente se desmonta
      }
    }
  }, [audio, onEnded])

  const handlePause = isPaused => {
    setPlaying(!isPaused)
  }
  const handlePlay = isPlaying => {
    setPlaying(isPlaying)
    onClick()
  }

  const handleVisit = val => {
    onClick(val)
  }
  React.useEffect(() => {
    //console.log("hola")
    //console.log(audio?.seekable)
    //console.log(curTime)
    /* console.log(duration)
    console.log(playing) */
  }, [curTime, duration, audio])

  return (
    <>
      <div
        className="w-full relative h-52 md:h-56 lg:h-72"
        style={{ backgroundColor: "red" }}
      >
        <div className="absolute w-full h-full">
          <img src={portrait} className="w-full h-full object-cover"></img>
        </div>
        <div className="absolute w-full h-full bg-white bg-opacity-90">
          <div className="flex flex-col justify-between h-full p-4">
            <div className="flex justify-between">
              <div className="rounded-lg w-16 h-32">
                <img
                  className="w-full rounded-lg h-full object-cover"
                  src={portrait}
                  alt={portrait.nombre}
                ></img>
              </div>
              <div>
                <ControlBar
                  audio={audio}
                  onPause={handlePause}
                  onPlay={handlePlay}
                ></ControlBar>
              </div>
              <div style={{ visibility: "hidden" }}>
                <BiHeart color={"#00CEA9"} size={35}></BiHeart>
              </div>
            </div>
            <ProgressBar audio={audio}></ProgressBar>
          </div>
        </div>
      </div>
      <div>
        <audio id="audio" onEnded={onEnded}>
          <source src={source} />
          Tu navegador no soporta contenidos de audio
        </audio>
      </div>

      <SharingBar></SharingBar>
    </>
  )
}

export default AudioPlayer
