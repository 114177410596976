import * as React from "react"
import { createContentReproduced, getAudio } from "../../../api/content"
import ContentPageLayout from "../../../components/ContentPageLayout"
import { ReactAudio } from "reactjs-media"
import StarIcon from "@material-ui/icons/Star"
import CloudDownloadIcon from "@material-ui/icons/CloudDownload"
import ShareIcon from "@material-ui/icons/Share"
import CastConnectedIcon from "@material-ui/icons/CastConnected"
import SCButton from "../../../components/buttons/SCButton"
import AudioPlayer from "../../../components/media/audio-player"
import config, { firebaseInit } from "../../../config"
import firebase from "firebase"
import { navigate } from "gatsby-link"
import Spinner from "../../../components/Spinner"
import { Link } from "gatsby"
import { BiLeftArrowAlt } from "@react-icons/all-files/bi/BiLeftArrowAlt"
import RatingPopup from "../../../components/modals/RaitingPopup"
import PremiumButton from "../../../components/buttons/PremiumButton"

const AudioPage = ({ ...props }) => {
  const audioId = props.params.id
  const [audio, setAudio] = React.useState()

  const profileUri = `${config.serverUri}/users`
  const [userHasSuscription, setUserHasSuscription] = React.useState(null)
  const [isContentPremium, setIsContentPremium] = React.useState(null)
  const [audioSource, setAudioSource] = React.useState()
  const [audioPortrait, setAudioPortrait] = React.useState()
  const [firstClick, setFirstClick] = React.useState(false)
  const [infoSeccion, setInfoSeccion] = React.useState([])
  const [timeUse, setTimeUse] = React.useState(0)
  const [runTime, setRunTime] = React.useState(false)
  const [userToken, setUserToken] = React.useState(null)
  const [dontShowAnymore, setDontShowAnymore] = React.useState(false)
  const [ratingTimer, setRatingTimer] = React.useState(null)
  const [popupShown, setPopupShown] = React.useState(false)

  React.useEffect(() => {
    getAudio(audioId).then(data => {
      console.log(data)
      if (data) {
        if (data.portrait.length > 0) {
          let portraitUrl = data.portrait[0].url
          if (
            !portraitUrl.startsWith(`${config.serverUri}`) &&
            !portraitUrl.startsWith("http")
          ) {
            portraitUrl = `${config.serverUri}${portraitUrl}`
          }
          setAudioPortrait(portraitUrl)
        }
        if (data.media) {
          let mediaUrl = data.media.url
          if (
            !mediaUrl.startsWith(`${config.serverUri}`) &&
            !mediaUrl.startsWith("http")
          ) {
            mediaUrl = `${config.serverUri}${mediaUrl}`
          }
          setAudioSource(mediaUrl)
        } else {
          let mediaSource = data.mediaSource
          if (
            !mediaSource.startsWith(`${config.serverUri}`) &&
            !mediaSource.startsWith("http")
          ) {
            mediaSource = `${config.serverUri}${mediaSource}`
          }
          setAudioSource(mediaSource)
        }
        setAudio(data)
      }
    })
  }, [props.params.id])

  React.useEffect(() => {
    if (audio != undefined) {
      // console.log('audio contenido tipo')
      // console.log(audio)
      setIsContentPremium(audio.tipoSuscripcion)
    }
    console.log(audio)
  }, [audio])

  React.useEffect(() => {
    firebaseInit(firebase)
    firebase.auth().onAuthStateChanged(user => {
      if (user) {
        user.getIdToken(true).then(token => {
          setUserToken(token)
        })
        fetch(profileUri + `/?email=${user.email}`)
          .then(response => {
            response.json().then(data => {
              //paid == premium
              // console.log(data[0])
              setInfoSeccion({ idUser: data[0].id, name: data[0].nombre })
              setUserHasSuscription(data[0].suscription)
            })
          })
          .catch(error => {
            console.log(error)
          })
          .catch(error => {
            console.log(error)
          })
      } else {
        console.log("user is null")
        setUserHasSuscription(false)
      }
    })
  }, [])

  const handleVisitAudios = () => {
    if (!firstClick) {
      setFirstClick(true)
      const info = {
        idVideo: audio.id,
        nombreVideo: audio.nombre,
      }

      const info2 = {
        idVideo: audio.id,
        nombreVideo: audio.nombre,
        idUser: infoSeccion.idUser,
        nombreUser: infoSeccion.nombre,
      }
      if (window.gtag) {
        window.gtag("event", `Audio-${audio.nombre}-Web`, info)
        window.gtag(
          "event",
          `Audio-${audio.nombre}-Consumido-${infoSeccion.nombre}-Web`,
          info2
        )
      }
    }
  }

  React.useEffect(() => {
    let intervalId

    if (runTime && timeUse < 120) {
      intervalId = setInterval(() => {
        setTimeUse(prevTime => prevTime + 1)
      }, 1000)
    } else {
      clearInterval(intervalId)
    }

    return () => {
      clearInterval(intervalId)
    }
  }, [runTime])

  React.useEffect(() => {
    if (audio !== undefined) {
      // console.log(timeUse)
      if (timeUse === 120) {
        saveContentView()
        setRunTime(false)
        // console.log('entro')
      }
    }
  }, [timeUse])

  const handleAudioEnded = () => {
    setPopupShown(true)
  }

  const handlePlayAudio = audioInfo => {
    const b2bTime = JSON.parse(localStorage.getItem("B2B_PLAY"))
    if (b2bTime === null) {
      const data = {
        start: Date.now(),
        content: audioInfo,
        token: userToken,
      }

      localStorage.setItem("B2B_PLAY", JSON.stringify(data))
    } else {
      console.log(b2bTime.start)
    }
  }

  const saveContentView = () => {
    const data = {
      contenidoId: "" + audio.id,
      contenido: "" + audio.nombre,
    }

    createContentReproduced(userToken, data).then(res => {
      console.log(res)
    })
  }

  const closeRatingPopup = () => {
    setDontShowAnymore(true)
    setPopupShown(false)
  }

  return (
    <ContentPageLayout>
      {infoSeccion.idUser && popupShown && !dontShowAnymore && (
        <RatingPopup
          closeModal={closeRatingPopup}
          userId={infoSeccion.idUser}
          classId={audio.id}
          coachId={audio.coach.id}
          portrait={audio.portrait[0] ? audioPortrait : "/images/error.jpg"}
        />
      )}

      {audio ? (
        isContentPremium && userHasSuscription != null ? (
          (isContentPremium == "premium" && userHasSuscription == "premium") ||
          isContentPremium == "free" ? (
            <>
              <div>
                <div>
                  <div
                    className="flex relative"
                    style={{ height: 240, width: "100%" }}
                  >
                    <img
                      className="absolute"
                      src="https://cms.shiftmexico.com/uploads/banner_superior_planes_25c45a906e.png"
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                      }}
                      alt={props.name}
                    ></img>
                    <div className="absolute flex p-5 h-full flex-col">
                      <h1 className="text-white ">Meditación</h1>
                      <p className="text-white">
                        Lleva la energía a tu día aumentando tu concentracíon y
                        productividad, además de mejorar tu estado de ánimo
                        creando un tono positivo, integrando tu mente, cuerpo y
                        espiritu.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="w-11/12 my-6 mx-auto flex items-center">
                  <Link to={`/sections/menteycuerpo`}>
                    {" "}
                    <BiLeftArrowAlt className="text-3xl" />{" "}
                  </Link>
                  <span className="pl-4">Mente y Cuerpo / Meditación </span>
                </div>
                <div className="w-11/12 mx-auto my-0">
                  <div className="p-8">
                    <h2 className="text-sc-primary">{audio.nombre}</h2>
                    <h2 className="text-sm">{audio.descripcionCorta}</h2>
                  </div>
                  <div className="flex flex-col md:flex-row md:p-8 pl-8 pr-8">
                    <div className="w-full">
                      <AudioPlayer
                        portrait={
                          audio.portrait[0]
                            ? audioPortrait
                            : "/images/error.jpg"
                        }
                        onEnded={handleAudioEnded}
                        source={audioSource}
                        onClick={() => {
                          setRunTime(true)
                          handlePlayAudio(audio)
                          handleVisitAudios()
                        }}
                      />
                    </div>
                    <div className="p-8 w-full">
                      <div className="pb-4">
                        <div className="font-bold">Objetivo</div>
                        <div>{audio.descripcion}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <div className="w-full h-screen flex flex-col items-center justify-center p-10">
              <p className="text-3xl">
                Se necesita ser usuario Shifter Premium para poder visualizar
                este contenido
              </p>
              <div className="w-72 mt-8 mx-auto">
                <PremiumButton
                  onClick={() => navigate("/sections/menteycuerpo")}
                >
                  Hazte premium
                </PremiumButton>
              </div>
            </div>
          )
        ) : (
          <div className="w-auto mx-auto">
            <Spinner message="Cargando..." />
          </div>
        )
      ) : null}
    </ContentPageLayout>
  )
}

export default AudioPage
