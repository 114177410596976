import { useState, useEffect } from "react"

function useAudioPlayer() {
  const [duration, setDuration] = useState()
  const [curTime, setCurTime] = useState()
  const [playing, setPlaying] = useState(false)
  const [clickedTime, setClickedTime] = useState()
  const [audio, setAudio] = useState()
  // const [sourceConnected, setSourceConnected] = useState(false)

  let analyser = null
  let context = null
  let ctx = null

  useEffect(() => {
    const audioElement = document.getElementById("audio")

    if (audioElement) {
      const setAudioData = () => {
        setDuration(audioElement.duration)
        setCurTime(audioElement.currentTime)
        setAudio(audioElement)
      }

      const setAudioTime = () => {
        setCurTime(audioElement.currentTime)
      }

      audioElement.addEventListener("loadeddata", setAudioData)
      audioElement.addEventListener("timeupdate", setAudioTime)

      return () => {
        audioElement.removeEventListener("loadeddata", setAudioData)
        audioElement.removeEventListener("timeupdate", setAudioTime)
      }
    }
  }, [audio])

  useEffect(() => {
    const audio = document.getElementById("audio")

    // if (!sourceConnected) {
    //   const AudioContext = window.AudioContext || window.webkitAudioContext
    //   context = new AudioContext()
    //   analyser = context.createAnalyser()
    //   let source = context.createMediaElementSource(audio)
    //   source.connect(analyser)
    //   source.connect(context.destination)
    //   setSourceConnected(true)
    //   frameLoop()
    // }
    // state setters wrappers
    const setAudioData = () => {
      setDuration(audio.duration)
      setCurTime(audio.currentTime)
      setAudio(audio)
    }

    const setAudioTime = () => {
      setCurTime(audio.currentTime)
    }

    // DOM listeners: update React state on DOM events
    audio.addEventListener("loadeddata", setAudioData)

    audio.addEventListener("timeupdate", setAudioTime)

    // React state listeners: update DOM on React state changes
    // playing && sourceConnected ? audio.play() : audio.pause()
    playing ? audio.play() : audio.pause()
    if (clickedTime && clickedTime !== curTime) {
      audio.currentTime = clickedTime
      setClickedTime(null)
    }

    // effect cleanup
    return () => {
      audio.removeEventListener("loadeddata", setAudioData)
      audio.removeEventListener("timeupdate", setAudioTime)
    }
  })

  const frameLoop = () => {
    window.requestAnimationFrame(frameLoop)
    const canvas = document.getElementById("canvas-graph")
    ctx = canvas.getContext("2d")
    const fbc_array = new Uint8Array(analyser.frequencyBinCount)
    analyser.getByteFrequencyData(fbc_array)
    ctx.clearRect(0, 0, canvas.width, canvas.height)
    ctx.fillStyle = "#00CCFF"
    let bars = 100
    for (let i = 0; i < bars; i++) {
      let bar_x = i * 3
      let bra_width = 2
      let bar_height = -(fbc_array[i] / 2)
      ctx.fillRect(bar_x, canvas.height, bra_width, bar_height)
    }
  }

  return {
    audio,
    curTime,
    duration,
    playing,
    setPlaying,
    setClickedTime,
  }
}

export default useAudioPlayer
